import { data as profileData, notification, countdown } from '../stores.js';
import { version } from '../../../../package.json';
import logins from '../../../../svr/providers/logins.json';
import { CLIENT_REQUEST_TIMEOUT_MS } from '../constants.js';
import { _, locale } from 'svelte-i18n';
import QRCode from 'qrcode';
import { replace as replaceRoute } from 'svelte-spa-router';
import parser from 'ua-parser-js';
import * as sort from './sort.js';

const baseURL = '/api/v1';

function timeoutPromise(promise) {
	return new Promise((resolve, reject) => {
		const timeoutId = setTimeout(() => {
			reject(new Error(408));
		}, CLIENT_REQUEST_TIMEOUT_MS);
		promise.then(
			(res) => {
				clearTimeout(timeoutId);
				resolve(res);
			},
			(err) => {
				clearTimeout(timeoutId);
				reject(err);
			}
		);
	});
}

export const readTranslatedKey = (key, valuesObj) => {
	let translated;
	locale.subscribe((lang) => {
		if (lang) {
			_.subscribe((val) => {
				translated = val(key, valuesObj);
			});
		}
	});
	return translated;
};

async function send({ method, path, data, picture, renewSession = true }) {
	const errMap = {
		DUPLICATE_VALUE: readTranslatedKey('Entry already exists'),
		INVALID_CODE: readTranslatedKey('Incorrect verification code'),
		NOT_USER_SUBJECT: readTranslatedKey('Account already linked with another wallet')
	};

	const opts = { method, headers: {} };
	if (picture) {
		opts.body = data;
	} else if (data) {
		opts.headers['Content-Type'] = 'application/json';
		opts.body = JSON.stringify(data);
	}

	return timeoutPromise(fetch(baseURL + path, opts))
		.then((res) => {
			if (!res.ok) throw res;
			return res.json();
		})
		.then((json) => {
			const isPersonalLoggedIn = 'isPersonalLoggedIn' in json && json.isPersonalLoggedIn;
			const isManagedLoggedIn = 'isManagedLoggedIn' in json && json.isManagedLoggedIn;
			if (isPersonalLoggedIn || isManagedLoggedIn) {
				countdown.start();
			} else {
				countdown.stop();
			}
			if (!renewSession) countdown.stop();
			return json;
		})
		.catch(async (err) => {
			if (err instanceof TypeError) {
				notification.set({
					text: readTranslatedKey('Network error, please try again'),
					type: 'error'
				});

				throw err;
			}

			if (renewSession) {
				countdown.start();
			}

			try {
				let res = err.clone();
				switch (res.status) {
					case 401: {
						const json = await res.json();
						if (json?.error?.message === 'NO_SESSION') {
							notification.set({
								text: 'Session expired. Please log in again.',
								type: 'error'
							});
							countdown.stop();
							profileData.update((d) => {
								return {
									...d,
									isPersonalLoggedIn: false
								};
							});
							return replaceRoute('/login');
						}
						break;
					}
					case 400:
					case 404: {
						const { error } = await res.json();
						if (errMap[error?.message]) {
							notification.set({
								text: errMap[error.message],
								type: 'error'
							});
						}
						break;
					}
				}
				/* 
				We need an empty catch block here as /keepalive response is plain text and the above code
				tries to parse JSON and fails. TODO: Refactor code to not make assumptions above responses being JSON.
			*/
				// eslint-disable-next-line no-empty
			} catch (err) {}

			if (err.status >= 500) {
				notification.set({
					text: readTranslatedKey('Something went wrong. Please try again later.'),
					type: 'error'
				});
				countdown.stop();
			}

			if (err.message == 408) {
				notification.set({
					text: readTranslatedKey('Request timed out, please try again'),
					type: 'error'
				});
				countdown.stop();
			}
			// TODO: THIS IS HACK BECAUSE CONTENT TYPE IS TEXT
			if (path === '/login/keep_alive') return;
			throw err;
		});
}

export const get = (path, renewSession) => {
	return send({ method: 'GET', path, renewSession });
};

export const del = (path, data, renewSession) => {
	return send({ method: 'DELETE', path, data, renewSession });
};

export const post = (path, data, picture, renewSession) => {
	return send({ method: 'POST', path, data, picture, renewSession });
};

export const put = (path, data, renewSession) => {
	return send({ method: 'PUT', path, data, renewSession });
};

export const checkVersion = (result) => {
	const versionServer = result && result.version;
	if (versionServer) {
		const color = version === versionServer ? 'color: green;' : 'color: red;';
		console.log(`%c Running Client v${version} | Server v${versionServer}`, color);
	}
};

export const logPlausibleEvent = async (body) => {
	if (localStorage.getItem('plausible_ignore') == 'true') {
		console.info('Ignoring Event: localStorage flag');
		return;
	}
	// https://plausible.io/docs/events-api
	const _body = {
		w: window.innerWidth, //window width
		r: document.referrer || null, //referer
		d: window.location.hostname, //domain
		...body, //u: url
		n: body.n || 'pageview' //type of event ['pageview', '<name of event>']
	};
	if (window.location.pathname === '/') {
		_body.u = window.location.origin + body.u;
	} else {
		_body.u = window.location.origin + window.location.pathname + (body.u || '');
	}
	try {
		await fetch('/api/event', {
			method: 'POST',
			body: JSON.stringify(_body),
			headers: {
				'Content-Type': 'application/json'
			}
		});
		console.info(`Event sent: ${_body.u} (${_body.n})`);
	} catch (err) {
		console.error(err);
	}
};

export const getDeviceTheme = () => {
	if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
		return 'dark';
	} else {
		return 'light';
	}
};

export const clearLocalAndSessionStorage = () => {
	const plausible_ignore = localStorage.getItem('plausible_ignore');
	const language = localStorage.getItem('lang');
	localStorage.clear();
	if (plausible_ignore) {
		localStorage.setItem('plausible_ignore', true);
	}
	if (language) {
		localStorage.setItem('lang', language);
	}
	sessionStorage.clear();
};

export const clearLocalStorage = (ignoreValues = []) => {
	for (const [key] of Object.entries(localStorage)) {
		if (ignoreValues.includes(key)) continue;
		localStorage.removeItem(key);
	}
};

export const clearSessionStorage = (ignoreValues = []) => {
	for (const [key] of Object.entries(sessionStorage)) {
		if (ignoreValues.includes(key)) continue;
		sessionStorage.removeItem(key);
	}
};

export const trimEthAddress = (addr) => {
	return addr.slice(0, 6) + '...' + addr.slice(38);
};

export const getWallet = () => {
	if (window.ethereum?.isMetaMask) {
		return {
			slug: 'metamask',
			name: 'MetaMask',
			icon: 'https://cdn.hello.coop/images/metamask.svg'
		};
	} else if (window.ethereum) {
		return {
			slug: 'ethereum',
			name: 'Ethereum',
			icon: 'https://cdn.hello.coop/images/ethereum.svg'
		};
	} else {
		return false;
	}
};

export const getDisplay = (slug) => {
	if (!slug) return '';
	const display = logins.find((i) => i.slug === slug)?.display;
	if (display) return display;
	else return slug.charAt(0).toUpperCase() + slug.slice(1);
};

export const setAttributes = (node) => {
	node.setAttribute('nosociallinks', true);
	locale.subscribe((lang) => {
		if (lang) {
			node.setAttribute('lang', lang.split('-')[0]);
		}
	});
};

export const handleConsentResponse = (res) => {
	let responseURI;
	try {
		responseURI = new URL(res.uri);
	} catch (err) {
		throw new Error('Invalid redirect_uri', err);
	}
	for (const key in res.params) {
		responseURI.searchParams.append(key, res.params[key]);
	}
	if (!res.response_mode) {
		//we did not get a response_mode query param
		//set to response_type default response_modes
		if (res.response_type === 'code') {
			res.response_mode = 'query';
		} else if (res.response_type === 'id_token') {
			res.response_mode = 'fragment';
		}
	}

	sessionStorage.clear(); //clears authorize_query_params, app

	switch (res.response_mode) {
		case 'form_post': {
			const formRef = document.createElement('form');
			formRef.setAttribute('method', 'POST');
			formRef.setAttribute('action', res.uri);
			if (res.params) {
				for (const key in res.params) {
					const inputRef = document.createElement('input');
					inputRef.setAttribute('type', 'hidden');
					inputRef.setAttribute('name', key);
					inputRef.setAttribute('value', res.params[key]);
					formRef.appendChild(inputRef);
				}
				document.body.appendChild(formRef);
			}
			formRef.submit();
			break;
		}
		case 'query': {
			// Do nothing - already added as search params
			window.location.href = responseURI.href;
			break;
		}
		default: {
			// Fragment
			responseURI.href = responseURI.href.replace('?', '#');
			window.location.href = responseURI.href;
			break;
		}
	}
};

export const dedupe = (arr, key) => {
	//not an object
	if (!key) {
		const deduped = [...new Set(arr)];
		if (deduped.length !== arr.length) {
			console.warn(`Found duplicates in ${JSON.stringify(arr, null, 2)}`);
		}
		return deduped;
	}

	const deduped = [];
	let hasDuplicates = false;
	for (const item of arr) {
		const keys = deduped.map((i) => i[key]);
		if (!keys.includes(item[key])) {
			deduped.push(item);
		} else {
			hasDuplicates = true;
		}
	}
	if (hasDuplicates) {
		console.warn(`(Using key: "${key}") Found duplicates in ${JSON.stringify(arr, null, 2)}`);
	}
	return deduped;
};

export const makeMastodonPreviewDataObj = (obj) => {
	const _obj = structuredClone(obj); //creates a deep clone
	return {
		id: _obj.id,
		avatar: _obj.avatar,
		bio: _obj.source?.note,
		header: _obj.header,
		metadata: _obj.source?.fields
	};
};

export const generateQR = async (text) => {
	try {
		return await QRCode.toString(text, {
			type: 'svg',
			color: {
				light: '#0000' // Transparent background
			}
		});
	} catch (err) {
		console.error(err);
	}
};

export const makeLabel = (obj) => {
	const entries = Object.keys(obj).length;
	if (!entries) return '';
	const labels = [];
	const firstLabel = obj[Object.keys(obj)[0]]?.label;
	if (firstLabel) {
		labels.push(firstLabel);
	}
	if (entries > 1) {
		const secondLabel = obj[Object.keys(obj)[1]]?.label;
		if (secondLabel) {
			labels.push(secondLabel);
		}
	}
	if (labels.length) {
		return `(${labels.join(' & ')})`;
	}
	return '';
};

//https://stackoverflow.com/a/40724354
//TODO: Use Intl.NumberFormat API when better supported
const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
export const abbreviateNumber = (number) => {
	// what tier? (determines SI symbol)
	const tier = (Math.log10(Math.abs(number)) / 3) | 0;

	// if zero, we don't need a suffix
	if (tier == 0) return number;

	// get suffix and determine scale
	const suffix = SI_SYMBOL[tier];
	const scale = Math.pow(10, tier * 3);

	// scale the number
	const scaled = number / scale;

	// format number and add suffix
	return scaled.toFixed(1) + suffix;
};

export const fetchWithTimeout = async (resource, options = {}) => {
	const { timeout = 8000 } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
	try {
		const response = await fetch(resource, {
			...options,
			signal: controller.signal
		});
		clearTimeout(id);
		return response;
	} catch (err) {
		if (err?.name === 'AbortError') {
			console.info(`Request for ${resource} timed out (${timeout}ms)`);
		}
		throw err;
	}
};

export const fetchWithRetry = async (resource, options = {}) => {
	const { totalRetries = 1, retryOnStatusCode = 401 } = options;
	let retries = 0;
	while (retries <= totalRetries) {
		const res = await fetch(resource, options);
		if (!res.ok) {
			if (retries === totalRetries) return res;
			if (res.status === retryOnStatusCode) {
				console.info(`Retrying request to ${resource}`);
				retries++;
				continue;
			}
		}
		return res;
	}
};

export const isValidDomain = function (domain) {
	// TODO: accept http://example.com, https://www.example.com but only send example.com to server
	return /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,63}$/.test(domain);
};

export const getRecommendedProviders = (providerHintsShown, providerHintsHidden) => {
	let recommendedProviders = ['google', 'email']; //defaults
	let _recommendedProviders = Array.from(recommendedProviders);
	if (
		parser(window.userAgent).os.name.includes('Mac') ||
		parser(window.userAgent).os.name.includes('iOS')
	) {
		recommendedProviders = _recommendedProviders = ['apple', ...recommendedProviders];
	} else if (parser(window.userAgent).os.name.includes('Windows')) {
		recommendedProviders = _recommendedProviders = ['microsoft', ...recommendedProviders];
	}
	//query param slugs
	if (providerHintsShown?.length) {
		recommendedProviders = [...providerHintsShown, ...recommendedProviders];
	}
	//query param slugs suffixed with --
	if (providerHintsHidden?.length) {
		recommendedProviders = recommendedProviders.filter((i) => !providerHintsHidden.includes(i)); //remove slugs suffixed with --
	}
	//defense for hiding all above fold provider via provider_hint query params
	if (!recommendedProviders?.length) {
		recommendedProviders = Array.from(_recommendedProviders);
		console.warn(
			'Reverting to showing default providers. You cannot demote all default providers.'
		);
	}
	recommendedProviders = [...new Set(recommendedProviders)]; //remove duplicates
	return recommendedProviders;
};

const deviceSupportsPasskey = async () => {
	// @simplewebauthn/browser
	// browserSupportsWebAuthn() && (await platformAuthenticatorIsAvailable()) && browserSupportsWebAuthnAutofill()
	try {
		const supportsWebAuthn =
			window?.PublicKeyCredential !== undefined && typeof window.PublicKeyCredential === 'function';
		if (!supportsWebAuthn) return false;
		const platformAuthenticatorAvailable =
			await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
		if (!platformAuthenticatorAvailable) return false;
		const conditionalMediationAvailable =
			await window.PublicKeyCredential.isConditionalMediationAvailable();
		if (!conditionalMediationAvailable) return false;
		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};

export const promptForPasskey = async (apiResponse = {}) => {
	if (sessionStorage.authorize_query_params?.includes('passkey--'))
		//provider_hint authorize query param
		return false;
	if (sessionStorage.remindPasskeyPromptLater) return false;
	// if(apiResponse.newDevice)
	// 	return false
	if (apiResponse.isNewUser) return false;
	if (apiResponse.noPasskeyPrompt) return false;
	if (apiResponse.hasPasskey) return false;
	if (
		!['mobile', 'tablet'].includes(parser(window.navigator.userAgent).device.type) && //we only support mobile devices
		!sessionStorage.authorize_query_params?.includes('passkeys=global')
	)
		//for debugging passkeys on desktop
		return false;
	const supportsPasskey = await deviceSupportsPasskey();
	if (!supportsPasskey) return false;

	return true;
};

function addUnverifiedSujectsToAccounts(profileData) {
	if (profileData.profile?.unverified_emails?.length) {
		for (const email of profileData.profile.unverified_emails) {
			profileData.profile.accounts.push({
				id: `email::${email}`,
				user_name: email,
				display: email,
				slug: 'email',
				preferred: false,
				verifiedAt: false
			});
		}
	}

	if (profileData.profile?.unverified_phones?.length) {
		for (const phone of profileData.profile.unverified_phones) {
			profileData.profile.accounts.push({
				id: `phone::${phone}`,
				user_name: phone,
				display: phone,
				slug: 'phone',
				preferred: false,
				verifiedAt: false
			});
		}
	}
}

export function updateAccounts(profileData) {
	//add sortrank, sortlabel and unverified accounts to accounts array
	addUnverifiedSujectsToAccounts(profileData);
	profileData.profile.accounts = sort.addSortRank(profileData.profile.accounts);
	profileData.profile.accounts = sort.addSortLabel(profileData.profile.accounts);

	return profileData;
}
