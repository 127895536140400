import {
	get,
	del,
	post,
	put,
	checkVersion,
	handleConsentResponse,
	logPlausibleEvent,
	getDeviceTheme,
	clearLocalAndSessionStorage,
	readTranslatedKey,
	getDisplay
} from './helper.js';
import {
	data,
	notification,
	countdown,
	showSpinner,
	mergeAccounts,
	showUpgradePreferredModal,
	isRemoteAuthClient
} from '../stores.js';
import { locale } from 'svelte-i18n';

const addSourcePropToPictureObj = (picturesArr) => {
	picturesArr.forEach((pictureObj) => {
		if (!pictureObj) return; //defense for null or undefined
		if (!Object.prototype.hasOwnProperty.call(pictureObj, 'source')) {
			if (pictureObj.default) {
				pictureObj.source = 'default';
			} else if (pictureObj.uploaded) {
				pictureObj.source = 'upload';
			} else {
				pictureObj.source = pictureObj.display;
			}
		}
	});
	return picturesArr;
};

export const getProfile = async (query, { showNotification = true } = {}) => {
	let url = '/profile';
	let params = new URLSearchParams(query);
	if (query) {
		params.append('prefers-color-scheme', getDeviceTheme());
		params.append('language', window.navigator.language);
	}
	let result;
	if (params.toString()) {
		result = await get(`${url}?` + params.toString());
	} else {
		result = await get(url);
	}
	if (result?.profile?.language) {
		//svelte-i18n crashes if initialLocale has commas
		//expected type for result?.profile?.language is string and not array
		//stringify and check even if array is passed
		let language = result?.profile?.language?.toString()?.split(',')?.[0];
		if (language) {
			localStorage.setItem('lang', language);
			locale.set(language);
		}
	}
	if (result?.profile?.pictures) {
		result.profile.pictures = addSourcePropToPictureObj(result.profile.pictures);
	}
	if (result?.upgrade) {
		showNotification = false;
		showUpgradePreferredModal.set(result?.upgrade);

		//Start of Wiz Upgrade Funnel
		//wizard upgrade funnel state is not already sent + is logged in + is in wizard
		const isInWizard = !result?.profile?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		if (!sessionStorage.wiz_upgrade_funnel && result.isPersonalLoggedIn && isInWizard) {
			const contact_slug = result?.upgrade?.preferred?.slug;
			const email_domain = result?.upgrade?.preferred?.user_name?.split('@')?.[1];
			const upgrade_slug = result?.upgrade?.upgrade?.slug;
			sessionStorage.setItem('wiz_upgrade_funnel', 'wiz_upgrade_prompt');
			logPlausibleEvent({
				n: 'Wiz Upgrade Prompt',
				p: { contact_slug, email_domain, upgrade_slug },
				u: '/'
			});
		}
	}
	if (result?.merge) {
		showNotification = false;
		mergeAccounts.set(result?.merge);
	}
	checkVersion(result);
	if (showNotification && result.notification) {
		if (result.notification?.type === 'link') {
			logPlausibleEvent({ u: `/link/${result.notification?.slug}`, n: 'action' });
		} else if (result.notification?.type === 'authority') {
			const id =
				result.notification.preferred ||
				result.notification.recovery ||
				result.notification.no_recovery;
			const slug = result.profile?.accounts?.find((i) => i.id === id).slug;
			logPlausibleEvent({ u: `/verify/${slug}`, n: 'action' });
		}

		if (result.notification?.type !== 'merge') {
			if (result.notification?.attribute && result.notification?.slug) {
				const claim =
					result.notification.attribute.charAt(0).toUpperCase() +
					result.notification.attribute.substring(1);
				notification.set({
					text: readTranslatedKey(`${claim} from {provider} has been added`, {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					type: 'success'
				});
			} else if (result.notification?.slug) {
				notification.set({
					text: readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					type: 'success'
				});
			}
		}
	}

	return result;
};

export const getInvite = async (query, { showNotification = true } = {}) => {
	const url = '/invite';
	let params = new URLSearchParams(query);
	if (query) {
		params.append('prefers-color-scheme', getDeviceTheme());
		params.append('language', window.navigator.language);
	}
	let result;
	if (params.toString()) {
		result = await get(`${url}?` + params.toString());
	} else {
		result = await get(url);
	}
	if (result?.upgrade) {
		showNotification = false;
		showUpgradePreferredModal.set(result?.upgrade);
	}
	if (result?.merge) {
		showNotification = false;
		mergeAccounts.set(result?.merge);
	}
	checkVersion(result);
	if (showNotification && result.notification) {
		if (result.notification?.type === 'link') {
			logPlausibleEvent({ u: `/link/${result.notification?.slug}`, n: 'action' });
		} else if (result.notification?.type === 'authority') {
			const id = result.notification.preferred || result.notification.recovery;
			const slug = result.profile?.accounts?.find((i) => i.id === id).slug;
			logPlausibleEvent({ u: `/verify/${slug}`, n: 'action' });
		}

		if (result.notification?.type !== 'merge') {
			if (result.notification?.attribute && result.notification?.slug) {
				const claim =
					result.notification.attribute.charAt(0).toUpperCase() +
					result.notification.attribute.substring(1);
				notification.set({
					text: readTranslatedKey(`${claim} from {provider} has been added`, {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					type: 'success'
				});
			} else if (result.notification?.slug) {
				notification.set({
					text: readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					type: 'success'
				});
			}
		}
	}
	return result;
};

export const postDeviceNoPrompt = async (device_id) => {
	if (!device_id) {
		console.info('device_id missing');
		return;
	}
	return await post('/device/no_prompt/' + device_id);
};

export const deleteDeviceNoPrompt = async (device_id) => {
	if (!device_id) {
		console.error('device_id not passed in');
		return;
	}
	return await del('/device/no_prompt/' + device_id);
};

const listenForInAppMessages = () => {
	const evtSource = new EventSource('/api/v1/login/in_app');
	evtSource.addEventListener('keep-alive', (event) => {
		console.log('keep-alive: ' + event.data);
	});
	evtSource.addEventListener('authorization_response', (event) => {
		try {
			const json = JSON.parse(event.data);
			if (!json) throw json;
			data.set({}); //reset data
			window.location.href = window.location.pathname + '#' + new URLSearchParams(json);
		} catch (err) {
			console.error(err);
		} finally {
			evtSource.close();
		}
	});
};

const delayedListenForInAppMessages = () => setTimeout(listenForInAppMessages, 1000);

export const postLoginProvider = async ({
	slug = '',
	body = {},
	server = '',
	access = false
} = {}) => {
	let url = '';
	if (access) {
		url = '/login/access/redirect/';
	} else {
		url = '/login/redirect/';
	}
	url += slug;
	const queryParams = new URLSearchParams();
	if (window.isWalletApp) {
		queryParams.set('redirect_path', '/');
	} else if (window.isWalletAuthorizeApp) {
		queryParams.set('redirect_path', '/authorize');
	} else if (window.isWalletMastodonApp) {
		queryParams.set('redirect_path', '/mastodon');

		//send to mastodon/twitter page if logging in with mastodon
		if (slug === 'mastodon') {
			sessionStorage.setItem('currentMastodonPage', '/twitter');
		}
	} else if (window.isWalletInviteApp) {
		queryParams.set('redirect_path', '/invite');
	}
	if (server) {
		queryParams.set('server', server);
	}
	if (access) {
		await logPlausibleEvent({ u: `/start/login/access/${slug}`, n: 'action' });
	} else {
		await logPlausibleEvent({ u: `/start/login/${slug}`, n: 'action' });
	}
	const paramsStr = queryParams.toString();
	if (paramsStr) {
		url += '?' + paramsStr;
	}
	const res = await post(url, body, null, false);
	if (!res?.redirect) throw new Error('Could not find redirect_uri');
	delayedListenForInAppMessages();
	return res;
};

export const postLinkProvider = async ({
	slug = '',
	body = {},
	attribute = '',
	server = '',
	access = false
} = {}) => {
	let url = '';
	if (access) {
		url = '/link/access/redirect/';
	} else {
		url = '/link/redirect/';
	}
	url += slug;
	if (attribute) url += '/' + attribute;
	const queryParams = new URLSearchParams();
	if (window.isWalletApp) {
		queryParams.set('redirect_path', '/');
	} else if (window.isWalletAuthorizeApp) {
		queryParams.set('redirect_path', '/authorize');
	} else if (window.isWalletMastodonApp) {
		queryParams.set('redirect_path', '/mastodon');

		//send to mastodon/twitter page if linking mastodon
		//dont overwrite any existing sessionstorage page,
		// eg: linking avatar with mastodon should not redirect to metadata page
		//if mastodon acc linked from eg: header page (attribute), we dont want to send back to avatar
		if (
			slug === 'mastodon' &&
			!attribute &&
			(!sessionStorage.page || sessionStorage.page === '/')
		) {
			sessionStorage.setItem('currentMastodonPage', '/twitter');
		}
	} else if (window.isWalletInviteApp) {
		queryParams.set('redirect_path', '/invite');
	}
	if (server) {
		queryParams.set('server', server);
	}
	if (access) {
		await logPlausibleEvent({ u: `/start/link/access/${slug}`, n: 'action' });
	} else {
		await logPlausibleEvent({ u: `/start/link/${slug}`, n: 'action' });
	}
	const paramsStr = queryParams.toString();
	if (paramsStr) {
		url += '?' + paramsStr;
	}
	const res = await post(url, body);
	if (!res?.redirect) throw new Error('Could not find redirect_uri');
	delayedListenForInAppMessages();
	return res;
};

export const postLoginEmail = async (email, resend) => {
	const res = await post(
		'/login/contact/email',
		{
			email
		},
		null,
		false
	);
	if (resend) {
		notification.set({
			text: readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			type: 'success'
		});
	} else {
		notification.set({
			text: readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			type: 'success'
		});
		logPlausibleEvent({ u: '/start/login/email', n: 'action' });
	}
	return res;
};

export const postLoginEmailCode = async (code) => {
	const res = await post(
		'/login/contact/email/code',
		{
			code
		},
		null,
		false
	);
	notification.set({
		text: '',
		type: ''
	});
	logPlausibleEvent({ u: '/login/email', n: 'action' });
	return res;
};

export const postLoginPhone = async (phone, resend) => {
	const res = await post(
		'/login/contact/phone',
		{
			phone
		},
		null,
		false
	);
	if (resend) {
		notification.set({
			text: readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			type: 'success'
		});
	} else {
		notification.set({
			text: readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			type: 'success'
		});
		logPlausibleEvent({ u: '/start/login/phone', n: 'action' });
	}
	return res;
};

export const postLoginPhoneCode = async (code) => {
	const res = await post(
		'/login/contact/phone/code',
		{
			code
		},
		null,
		false
	);
	notification.set({
		text: '',
		type: ''
	});
	logPlausibleEvent({ u: '/login/phone', n: 'action' });
	return res;
};

export const postVerifyEmail = async (email, resend) => {
	const res = await post('/verify/contact/email', {
		email
	});

	if (resend) {
		notification.set({
			text: readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			type: 'success'
		});
	} else {
		notification.set({
			text: readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			type: 'success'
		});
		logPlausibleEvent({ u: '/start/verify/email', n: 'action' });
	}
	return res;
};

export const postVerifyEmailCode = async (code) => {
	const res = await post('/verify/contact/email/code', {
		code
	});
	logPlausibleEvent({ u: '/verify/email', n: 'action' });
	return res;
};

export const postVerifyPhone = async (phone, resend) => {
	const res = await post('/verify/contact/phone', {
		phone
	});

	if (resend) {
		notification.set({
			text: readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			type: 'success'
		});
	} else {
		notification.set({
			text: readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			type: 'success'
		});
		logPlausibleEvent({ u: '/start/verify/phone', n: 'action' });
	}
	return res;
};

export const postVerifyPhoneCode = async (code) => {
	const res = await post('/verify/contact/phone/code', {
		code
	});
	logPlausibleEvent({ u: '/verify/phone', n: 'action' });
	return res;
};

export const postLoginEth = async (address) => {
	return await post(
		'/login/ethereum/challenge',
		{
			address,
			uri: window.location.origin + window.location.pathname
		},
		null,
		false
	);
};

export const postLoginEthChallenge = async (body) => {
	return await post(
		`/login/ethereum?prefers-color-scheme=${getDeviceTheme()}&language=${
			window.navigator.language
		}`,
		body,
		null
	);
};

export const postLinkEth = async (address) => {
	return await post(
		'/link/ethereum/challenge',
		{
			address,
			uri: window.location.origin + window.location.pathname
		},
		null,
		false
	);
};

export const postLinkEthChallenge = async (body) => {
	return await post('/link/ethereum', body);
};

export const postLinkEmail = async (email, resend) => {
	const res = await post('/link/contact/email', {
		email
	});

	if (resend) {
		notification.set({
			text: readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			type: 'success'
		});
	} else {
		notification.set({
			text: readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			type: 'success'
		});
		logPlausibleEvent({ u: '/start/link/email', n: 'action' });
	}
	return res;
};

export const postLinkEmailCode = async (code, showNotification = true) => {
	const res = await post('/link/contact/email/code', {
		code
	});
	if (!res.merge && showNotification) {
		notification.set({
			text: readTranslatedKey('Email has been added'),
			type: 'success'
		});
	}
	logPlausibleEvent({ u: '/link/email', n: 'action' });
	return res;
};

export const postLinkPhone = async (phone, resend) => {
	const res = await post('/link/contact/phone', {
		phone
	});

	if (resend) {
		notification.set({
			text: readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			type: 'success'
		});
	} else {
		notification.set({
			text: readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			type: 'success'
		});
		logPlausibleEvent({ u: '/start/link/phone', n: 'action' });
	}
	return res;
};

export const postLinkPhoneCode = async (code, showNotification = true) => {
	const res = await post('/link/contact/phone/code', {
		code
	});
	if (!res.merge && showNotification) {
		notification.set({
			text: readTranslatedKey('Phone has been added'),
			type: 'success'
		});
	}
	logPlausibleEvent({ u: '/link/phone', n: 'action' });
	return res;
};

export const postNoPrompt = async () => {
	return await post('/login/no_prompt', null, null, false);
};

export const deleteNoPrompt = async () => {
	return await del('/login/no_prompt', null, false);
};

export const postWizard = async () => {
	return await post('/profile/wizard');
};

export const deleteWizard = async () => {
	return await del('/profile/wizard');
};

export const getConsent = async (query, { showNotification = true } = {}) => {
	let url = '/consent';
	let params = new URLSearchParams(query);
	if (query) {
		params.append('prefers-color-scheme', getDeviceTheme());
		params.append('language', window.navigator.language);
	}
	let result;
	if (params.toString()) {
		result = await get(`${url}?` + params.toString());
	} else {
		result = await get(url);
	}
	if (result?.language) {
		//svelte-i18n crashes if initialLocale has commas
		//expected type for result?.language is string and not array
		//stringify and check even if array is passed
		let language = result?.language?.toString()?.split(',')?.[0];
		if (language) {
			localStorage.setItem('lang', language);
			locale.set(language);
		}
	}
	if (result?.release?.pictures) {
		result.release.pictures = addSourcePropToPictureObj(result.release.pictures);
	}
	if (result?.upgrade) {
		showNotification = false;

		showUpgradePreferredModal.set(result?.upgrade);
	}
	if (result?.merge) {
		showNotification = false;
		mergeAccounts.set(result?.merge);
	}
	if (result?.remoteDevice) {
		isRemoteAuthClient.set(true);
	}
	checkVersion(result);

	if (showNotification && result.notification?.type !== 'merge') {
		if (
			result.notification &&
			!['verified-name', 'existing-name', 'existing-username'].includes(
				result.notification?.attribute
			)
		) {
			if (result.notification?.attribute) {
				notification.set({
					text: `${
						result.notification.attribute.charAt(0).toUpperCase() +
						result.notification.attribute.substring(1)
					} from ${getDisplay(result.notification?.slug)} has been added`,
					type: 'success'
				});
			} else {
				notification.set({
					text: readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					type: 'success'
				});
			}
		}
	}

	if (result.app) {
		sessionStorage.setItem('app', JSON.stringify(result.app));
	}

	return result;
};

export const postConsent = async (body) => {
	return await post('/consent', body);
};

export const deleteConsent = async () => {
	try {
		showSpinner.set(true);
		const res = await del('/consent', null, false);
		handleConsentResponse(res);
	} catch (err) {
		showSpinner.set(false);
		console.error(err);
	}
};

const namesMap = {
	name: 'Full name',
	nickname: 'Preferred name',
	given_name: 'First name',
	family_name: 'Last name'
};

export const putName = async (type, name, showNotification = true) => {
	const res = await put(`/profile/${type}`, { [type]: name });

	const claim = namesMap[type];
	if (claim && showNotification) {
		notification.set({
			text: readTranslatedKey(`${claim} added`),
			type: 'success'
		});
	}

	return res;
};

export const deleteName = async (type, index) => {
	const res = await del(`/profile/${type}?ordinal=${index}`);

	const claim = namesMap[type] || 'Entry';
	if (claim) {
		notification.set({
			text: readTranslatedKey(`${claim} removed`),
			type: 'success'
		});
	}

	return res;
};

export const putPreferred = async (action, { redirectPathParam = false, server = null } = {}) => {
	let url = '/profile/authority';
	if (redirectPathParam) {
		const params = new URLSearchParams();
		if (window.isWalletApp) {
			params.set('redirect_path', '/');
		} else if (window.isWalletAuthorizeApp) {
			params.set('redirect_path', '/authorize');
		} else if (window.isWalletMastodonApp) {
			params.set('redirect_path', '/mastodon');
		} else if (window.isWalletInviteApp) {
			params.set('redirect_path', '/invite');
		}
		if (server) {
			params.set('server', server);
		}
		const paramsToString = params.toString();
		if (paramsToString) {
			url += `?${paramsToString}`;
		}
	}
	return await put(url, action);
};

export const deleteProfile = async () => {
	clearLocalAndSessionStorage();
	return await del('/profile', null, false);
};

export const postPicture = async (formData) => {
	const res = await post('/profile/picture', formData, true);

	notification.set({
		text: readTranslatedKey('Profile picture added'),
		type: 'success'
	});

	return res;
};

export const postLogo = async (id, formData) => {
	const logo = await post('/managed/' + id + '/logo', formData, true);
	const img = new Image();
	img.src = logo.url;
	const promise = new Promise((resolve) => {
		img.onload = () => {
			logo.width = img.naturalWidth;
			logo.height = img.naturalWidth;
			resolve(logo);
		};
		img.onerror = () => {
			resolve(logo);
		};
	});
	return promise;
};

export const postBanner = async (formData) => {
	const res = await post('/profile/banner', formData, true);

	notification.set({
		text: 'Banner added',
		type: 'success'
	});

	return res;
};

export const deletePicture = async (index) => {
	const res = await del(`/profile/picture?ordinal=${index}`, null);

	notification.set({
		text: readTranslatedKey('Profile picture removed'),
		type: 'success'
	});

	return res;
};

export const deleteDevice = async (id) => {
	const res = await del('/profile/device/' + id);

	notification.set({
		text: readTranslatedKey('Device removed'),
		type: 'success'
	});

	return res;
};

export const deleteApplication = async (id) => {
	const res = await del('/profile/application/' + id);

	notification.set({
		text: readTranslatedKey('Application removed'),
		type: 'success'
	});

	return res;
};

export const deleteManagedApplication = async ({ id, sub } = {}) => {
	const res = await del('/profile/application/' + id + '/managed/' + sub);

	notification.set({
		text: readTranslatedKey('Application removed'),
		type: 'success'
	});

	return res;
};

export const deleteProvider = async (id) => {
	const res = await del('/profile/account', { id });

	notification.set({
		text: readTranslatedKey('Provider removed'),
		type: 'success'
	});

	return res;
};

export const deleteUnverifiedProvider = async (index, type) => {
	const res = await del(`/profile/unverified_${type}?ordinal=${index}`);

	notification.set({
		text:
			type === 'email'
				? readTranslatedKey('Unverified email removed')
				: type === 'phone'
				? readTranslatedKey('Unverified phone removed')
				: readTranslatedKey('Unverified account removed'),
		type: 'success'
	});

	return res;
};

export const logout = async ({ clearSession = true } = {}) => {
	countdown.stop();
	if (clearSession) {
		clearLocalAndSessionStorage();
	}
	const res = await del('/login', null, false);
	await logPlausibleEvent({ u: '/logout', n: 'action' });
	return res;
};

export const keepAlive = async () => {
	return await get('/login/keep_alive');
};

export const changeLanguage = async (langShortCode) => {
	return await put('/profile/language/' + langShortCode);
};

export const generateBanner = async (prompt) => {
	return await post('/profile/generate/banner', { prompt });
};

export const deleteAccessToken = async (ordinal = 0) => {
	return await del(`/access?ordinal=${ordinal}`);
};

export const postRelMeMastodon = async (body) => {
	return await post('/profile/rel-me/mastodon', body);
};

export const deleteCookies = async () => {
	return await del('/cookies', null, false);
};

export const getMastodonVerifyCredentials = async (server, token) => {
	keepAlive(); //This is not Hellō API - so renew session manually
	try {
		const res = await fetch('https://' + server + '/api/v1/accounts/verify_credentials', {
			headers: {
				Authorization: 'Bearer ' + token
			}
		});
		if (!res.ok) throw res;
		return await res.json();
	} catch (err) {
		console.error(err);
		notification.set({
			text: `Something went wrong. It's not your fault.`,
			type: 'error'
		});
		throw err;
	}
};

export const getMastodonFollowings = async (server, id) => {
	keepAlive(); //This is not Hellō API - so renew session manually
	let nextPageEndpoint = true;
	let followings = [];
	while (nextPageEndpoint) {
		try {
			let res;
			if (typeof nextPageEndpoint === 'string' && nextPageEndpoint.startsWith('http')) {
				res = await fetch(nextPageEndpoint);
			} else {
				res = await fetch(`https://${server}/api/v1/accounts/${id}/following?limit=80`);
			}
			const json = await res.json();
			if (json?.length) {
				followings = [...followings, ...json];
			}
			const linkResHeader = res.headers.get('Link');
			if (linkResHeader?.includes(`rel="next"`)) {
				const nextPageLinkResHeader = linkResHeader.split(';')?.[0];
				nextPageEndpoint = nextPageLinkResHeader?.substring(1, nextPageLinkResHeader.length - 1);
			} else {
				nextPageEndpoint = false;
			}
		} catch (err) {
			console.error(err);
			notification.set({
				text: `Something went wrong. It's not your fault.`,
				type: 'error'
			});
			break;
		}
	}
	return followings;
};

export const postFollowMastodonAccount = async (server, id, token) => {
	try {
		const res = await fetch(`https://${server}/api/v1/accounts/${id}/follow`, {
			method: 'POST',
			headers: {
				Authorization: 'Bearer ' + token
			}
		});
		if (!res.ok) throw res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const postUnfollowMastodonAccount = async (server, id, token) => {
	try {
		const res = await fetch(`https://${server}/api/v1/accounts/${id}/unfollow`, {
			method: 'POST',
			headers: {
				Authorization: 'Bearer ' + token
			}
		});
		if (!res.ok) throw res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const getMastodonDiscovery = async (server) => {
	const discoEndpoint = '/api/v1/nodeinfo/2.0?server=' + server;
	//Not using helper GET method
	//this call can fail and helper GET puts up an error notification which we dont want
	const discoRes = await fetch(discoEndpoint);
	if (!discoRes.ok) throw discoRes;
	const discoJson = await discoRes.json();
	return discoJson;
};

export const getManagedDicsovery = async (email) => {
	const discoEndpoint = '/api/v1/managed/provider?email=' + email;
	//Not using helper GET method
	//this call can fail and helper GET puts up an error notification which we dont want
	const discoRes = await fetch(discoEndpoint);
	if (!discoRes.ok) throw discoRes;
	const discoJson = await discoRes.json();
	return discoJson;
};

export const getEmailProviderDiscovery = async (domain) => {
	const discoEndpoint = '/api/v1/email/provider/' + domain;
	//Not using helper GET method
	//this call can fail and helper GET puts up an error notification which we dont want
	const discoRes = await fetch(discoEndpoint);
	if (discoRes.status !== 200) throw discoRes;
	const discoJson = await discoRes.json();
	return discoJson;
};

export const postInvite = async (invitee = { email: null, local: false }) => {
	return await post('/invite', invitee);
};

export const putInvite = async (id) => {
	return await put('/invite/' + id);
};

export const deleteInvite = async (id) => {
	return await del('/invite/' + id);
};

export const postInviter = async (inviter = { name: null, email: null }) => {
	return await post('/inviter', inviter);
};

export const postUserCode = async (code) => {
	return await post('/user/code', { user_code: code });
};

export const deleteUserCode = async (code) => {
	return await del('/user/code?user_code=' + code);
};

export const getInvitation = async (id) => {
	return await get('/invitation/' + id);
};

export const acceptInvitation = async (id) => {
	return await put('/invitation/' + id);
};

export const deleteInvitation = async (id) => {
	return await del('/invitation/' + id);
};

export const postReportAbuse = async (id) => {
	return await post('/invitation/' + id + '/report');
};

export const getManagedLogos = async (id) => {
	const logos = await get('/managed/' + id + '/logos');
	let promises = [];
	for (const logo of logos) {
		const img = new Image();
		img.src = logo.url;
		const promise = new Promise((resolve) => {
			img.onload = () => {
				logo.width = img.naturalWidth;
				logo.height = img.naturalWidth;
				resolve(logo);
			};
			img.onerror = () => {
				console.warn('Failed to fetch logo at: ' + logo.url);
				resolve();
			};
		});
		promises.push(promise);
	}
	const res = await Promise.all(promises);
	return res.filter(Boolean); //filter out unresolved images
};

export const putManaged = async (id, body) => {
	return await put('/managed/' + id + '/who', body);
};

export const putManagedLogo = async (id, body) => {
	return await put('/managed/' + id + '/logo/select', body);
};
